





















































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    MainHeader,
    Empty,
  },
})
export default class Name extends Vue {
  private data: any = {};
  private tree: any = {};
  private get currentKu() {
    return this.$store.state.currentKu;
  }
  private changePart(index: any) {
    const parant: any = document.getElementById("scrollBox");
    const children: any = document.getElementById("scrollBox" + index);
    if (parant) {
      if (children) {
        const parentTop = parant.offsetTop;
        const childTop = children.offsetTop;
        parant.scrollTop = childTop - parentTop - 14;
      }
    }
  }
  private back() {
    this.$store.commit("updateIfCompose", false);
    this.$router.back();
  }
  private mounted() {
    if (this.$route.query.data) {
      const query: any = JSON.parse(this.$route.query.data as any);
      this.data["词条名称"] = query["名称"];
      this.data["分类"] = query["分类"];
    }
  }
}
